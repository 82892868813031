import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import ReduxStore from "../ReduxStore/ReduxStore";
import { getUser } from "../LoginUtils/LoginUtils";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
// <----------------Get AddToCart Response-------------->
export const AddToCartResponse = () => {
  // console.log("CartlistResponse---->",data);
  return {
    type: "ADD_TO_CART", // ACTION TO ADD TASK
  };
};

export const AddToCart = (id, product_qty) => (dispatch) => {
  dispatch({ type: FETCH_DATA_REQUEST });
  axios
    .post("https://paydeer.in/shop/Backend/public/api/addToCart", {
      user_id: getUser(),
      product_id: id,
      product_qty: product_qty,
    })
    .then((response) => {
      console.log(response);
      dispatch({ type: FETCH_DATA_SUCCESS });
      dispatch(GetCartlist());
      return dispatch(AddToCartResponse());
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: FETCH_DATA_FAILURE });
    });
};

// export const AddToCart = (id, product_qty) => {
//   // console.log("action");
//   return {
//     type: "ADD_CART", // ACTION TO ADD TASK
//     payload: {
//       //   id: new Date().getTime().toString(),
//       product_id: id,
//       product_qty: product_qty,
//     },
//   };
// };

// <----------------Get Cartlist-------------->
export const GetCartlist = () => (dispatch) => {
  dispatch({ type: FETCH_DATA_REQUEST });
  axios
    .post("https://paydeer.in/shop/Backend/public/api/cartDetail", {
      userId: getUser(),
    }) //cart List from server
    .then((response) => {
      console.log(response);
      if (response.data.status === false) {
        console.log(response.data.status);
        return dispatch(CartlistResponse(response.data.status));
      } else {
        return dispatch(CartlistResponse(response.data.data));
      }
    })
    .catch((error) => {
      console.log(error);
      return dispatch(CartlistResponse(error.status));
    });
};
// <----------------Get Cartlist Response-------------->
export const CartlistResponse = (data) => {
  return {
    type: "GET_CARTLIST", // ACTION TO ADD TASK
    payload: {
      cartList: data,
    },
  };
};
// <----------------Delete Address-------------->
export const DeleteAddress = (data) => {
  return {
    type: "DELETE_ADDRESS", // ACTION TO Delete address
    payload: {
      delete_add_id: data,
    },
  };
};

// <----------------Set Address-------------->
export const SetAddress = (data) => (dispatch) => {
  axios
    .post("https://paydeer.in/shop/Backend/public/api/addAddress", data)
    .then((response) => {
      // console.log(response);
      return dispatch(AddAddressResponse(response));
    })
    .catch((error) => {
      return {
        type: "ADDRESS_ADD", // ACTION TO ADD Addrress
        payload: {
          addressResponse: error,
        },
      };
    });
};

// <----------------Add Address Response-------------->
export const AddAddressResponse = (data) => {
  return {
    type: "ADDRESS_ADD",
    payload: {
      addressResponse: data,
    },
  };
};
// <----------------Delete Product-------------->
export const DeleteItem = (productId, userId) => {
  return {
    type: "DELETE_ITEM",
    payload: {
      userId: userId,
      productId: productId,
    },
  };
};

// <----------------GetAddress-------------->
export const GetAddress = () => (dispatch) => {
  axios
    .post("https://paydeer.in/shop/Backend/public/api/userAddresses", {
      id: getUser(),
    })
    .then((response) => {
      console.log(response);
      // console.log("i have got address list");
      return dispatch(GetAddressResponse(response));
    })
    .catch((error) => {
      console.log(error);
    });
};
// <----------------GetAddress Response-------------->
export const GetAddressResponse = (response) => {
  // console.log(response);
  return {
    type: "GET_ADDRESS",
    payload: {
      userAddress: response,
    },
  };
};
export const SetDeliveryAddress = (data) => {
  return {
    type: "SET_PRIMARY",
    payload: {
      primaryAddress: data,
    },
  };
};
// <----------Get the order Details--------->
export const GetOrderDetails =
  (mode, orderID = 0) =>
  (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST });
    axios
      .post("https://paydeer.in/shop/Backend/public/api/orderDetail", {
        user_id: getUser(),
      })
      .then((response) => {
        return dispatch(getCheckoutResponse(response.data.data, mode, orderID));
      })
      .catch((err) => {
        console.log(err);
      });
  };
const getCheckoutResponse = (response, mode, orderID) => {
  // let orderDetail = response[0];
  // console.log("Here")
  // console.log(mode);
  // switch (mode) {
  //   case "GET_BY_ORDER_ID":
  //     let orderByID = response[orderID];
  //     return{
  //       type: "LATEST_ORDER_DETAILS",
  //       payload: orderByID,

  //     }
  //   case "GET_LATEST":
  //     let orderDetail = response[response.length - 1];
  //     return {
  //       type: "LATEST_ORDER_DETAILS",
  //       payload: orderDetail,
  //     };
  // case "GET_ALL":
  // console.log(response);
  return {
    type: "ALL_ORDER_DETAILS",
    payload: response,
  };
  //   default:
  //     return {
  //       type: "ERROR",
  //       payload: [],
  //     };
  // }
};

export const SetAddLocation = (data1, data2) => {
  console.log(data1, data2 + "action ");
  return {
    type: "SET_LOCATION",
    payload: {
      lon: data2,
      lat: data1,
    },
  };
};
