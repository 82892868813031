import React, { useRef, useState, useEffect } from "react";
import "./Invoice.css";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import axios from "axios";

function Invoice(props) {
  const reportTemplateRef = useRef(null);

  const location = useLocation();
  const orderDetail = location.state.order;
  console.log(props);
  // const [orderDetail, setOrderDetail] = useState(location.state.order);
  const [amountArray, setAmountArray] = useState({});

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "p",
      format: "a4",
      unit: "pt",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  const getOrderDate = (orderDate) => {
    const date = new Date(orderDate);
    const dateString =
      date.getDate().toString() +
      "/" +
      date.getMonth().toString() +
      "/" +
      date.getFullYear().toString();
    return dateString;
  };
  console.log(amountArray);

  useEffect(() => {
    let oldPrice = 0;
    let totalAmount = 0;
    let taxAmount = 0;
    let shippingCost = 0;
    let grandTotal = 0;
    orderDetail?.order_item?.forEach((data) => {
      oldPrice += data?.product?.product_oldprice;
      totalAmount += data?.product?.product_price * data?.qty; // it should be multipled by quantity
      // totalAmount += data?.product?.product_price; // it should be multipled by quantity
    });
    taxAmount = Math.round(0.18 * totalAmount);
    if (totalAmount < 5000) shippingCost = 200;
    grandTotal = totalAmount + taxAmount + shippingCost;
    setAmountArray({
      oldPrice: oldPrice,
      totalAmount: totalAmount,
      taxAmount: taxAmount,
      shippingCost: shippingCost,
      grandTotal: grandTotal,
    });
    console.log(amountArray);
  }, []);

  return (
    <div
      id="invoice-container"
      className="invoice-container d-flex flex-column justify-content-center align-items-center invoice-heading invoice-para"
    >
      <div className="download invoice-heading invoice-para">
        <button
          className="button invoice-heading invoice-para"
          onClick={handleGeneratePdf}
          style={{ color: "white" }}
        >
          Download Invoice
        </button>
      </div>
      <div
        className="invoice invoice-heading invoice-para"
        ref={reportTemplateRef}
      >
        <div className="invoice-header invoice-heading invoice-para">
          <div className="heading">
            <h1 className="invoice-heading">Paydeer pvt. ltd.</h1>
            <h1 className="invoice-heading">Tax Invoice</h1>
          </div>
          <div className="clientDetails invoice-heading invoice-para">
            <div className="address d-flex flex-column invoice-heading invoice-para">
              <span>Paydeer Office vaishali Nagar</span>
              <span>Jaipur Rajasthan</span>
            </div>
            <div className="taxDetails d-flex flex-column">
              {/* <span>{getOrderDate(orderDetail.created_at)}</span> */}
            </div>
          </div>
        </div>
        <div className="midInvoice">
          {" "}
          <div className="invoice-header">
            <div className="heading">
              <h3 className="invoice-Sub-heading">Bill To</h3>
            </div>
            <div className="clientDetails">
              <div className="client d-flex flex-column">
                <span>{orderDetail?.name}</span>
                <span>{orderDetail?.address}</span>
                <span>
                  {orderDetail?.city} {orderDetail?.state}
                </span>
                <span>{orderDetail?.phone}</span>
              </div>
              <div className="taxDetails ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="miniHeading">Invoice Date</div>
                  <div>{getOrderDate(orderDetail?.created_at)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="billTable">
          <table align="center" border="1" width="100%">
            <tr id="tableHead">
              <td
                style={{
                  width: "5%",
                  color: " #18a3ae",
                  fontWeight: "bold",
                }}
              >
                ID
              </td>

              <td
                style={{
                  width: "35%",
                  color: " #18a3ae",
                  fontWeight: "bold",
                  display: "felx",
                  justifyContent: "center",
                }}
              >
                Description
              </td>

              <td
                style={{ width: "15%", color: " #18a3ae", fontWeight: "bold" }}
              >
                Quantity
              </td>
              <td
                style={{ width: "15%", color: " #18a3ae", fontWeight: "bold" }}
              >
                Rate
              </td>
              <td
                style={{ width: "15%", color: " #18a3ae", fontWeight: "bold" }}
              >
                Discount
              </td>
              <td
                style={{
                  width: "15%",
                  color: " #18a3ae",
                  fontWeight: "bold",
                }}
              >
                Amount
              </td>
            </tr>
            {orderDetail?.order_item?.map((data, idx) => {
              return (
                <tr id="col-2">
                  <td>{idx + 1}</td>

                  <td>{data?.product?.product_name}</td>

                  <td>{data?.qty}</td>

                  <td>&#8377; {data?.product?.product_oldprice}</td>

                  <td>
                    &#8377;{" "}
                    {data?.product?.product_oldprice -
                      data?.product?.product_price}{" "}
                  </td>

                  <td>&#8377; {data?.product?.product_price * data?.qty}</td>
                </tr>
              );
            })}
          </table>
        </div>
        <div className="invoiceFooter">
          <div className="invoiceFooterDetails" style={{ width: "50.7%" }}>
            <p className="my-4" style={{ color: "grey" }}>
              Thank you for Yours business
            </p>
          </div>
          <table
            className="invoiceFooterTable"
            align="center"
            border="1"
            width="49.3%"
          >
            <tr
              className="tableBottom"
              style={{ borderTop: "0px", width: "100%" }}
            >
              <td style={{ borderTop: "0px", width: "80%" }}>Sub total</td>

              <td style={{ borderTop: "0px", width: "20%" }}>
                &#8377; {amountArray?.totalAmount}
              </td>
            </tr>
            {/* <tr className="tableBottom">
              <td>Discount</td>

              <td>&#8377; 0</td>
            </tr> */}
            <tr className="tableBottom">
              <td>Tax Rate</td>

              <td>18%</td>
            </tr>
            <tr className="tableBottom">
              <td>Tax</td>

              <td>&#8377; {amountArray?.taxAmount}</td>
            </tr>
            <tr className="tableBottom">
              <td>Shipping</td>

              <td>&#8377; {amountArray?.shippingCost}</td>
            </tr>
            <tr
              className="tableBottom"
              style={{
                background: "#18a3ae",
                color: "white",
                fontWeight: "bold",
                fontSize: ".5rem",
              }}
            >
              <td>Total</td>

              <td>{`${amountArray?.grandTotal}`}</td>
              {/* <td>{`${amountArray.shippingCost + amountArray?.grandTotal}`}</td> */}
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
