// import TodoReducers from "./TodoReducers";
import AddressReducer from "./AddressReducer";

import { combineReducers } from "redux";
import CartReducer from "./CartReducer";
import OrderDetailsReducer from "./OrderDetailsReducer";
import UserReducer from "./UserReducer";

const RootReducer = combineReducers({ CartReducer, AddressReducer, OrderDetailsReducer,UserReducer });
// const rootReducer = combineReducers({ TodoReducers });
export default RootReducer;
