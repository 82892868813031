import axios from "axios";
import { getUser } from "../LoginUtils/LoginUtils";

const initialData = {
  list: [],
  cartLengthh: 0,
  isGetting: false,
  length: 0,
  isLoading: false,
  error: false,
};
const CartReducer = (state = initialData, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      console.log("AdD_to Cart");
      console.log(action);
      console.log(state.list);
      // let arrayList=state.list

      return {
        ...state,
        isGetting: false,
      };
    case "GET_CARTLIST":
      const { cartList } = action.payload;
      console.log(cartList);
      if (!cartList) {
        console.log(cartList);
        return { ...state, list: cartList, isGetting: false };
      }
      return {
        ...state,
        list: cartList,
        length: cartList.count,
        cartLengthh: cartList.details.length,
        isGetting: false,
      };
    case "DELETE_ITEM":
      const { productId } = action.payload;
      axios
        .post("https://paydeer.in/shop/Backend/public/api/cartItemDelete", {
          userId: getUser(),
          productId: productId,
        })
        .then((response) => {
          console.log(response);
          console.log(state.list.details);
        })
        .catch((error) => {
          console.log(error);
        });
      state.list.details = state.list.details.filter((val) => {
        if (val.product_id !== productId) return val;
      });
      state.list.count = state.list.details.length;
      console.log(state.list);
      return {
        ...state,
        length: state.list.count,
        list: state.list,
        length: state.list.length,
        cartLengthh: state.list.details.length,
        isGetting: false,
      };

    case "GET_BILL":
      const { billDetails, status } = action.payload;
      console.log(status);
      console.log(billDetails);
      if (status == 200) {
        return {
          ...state,
          orderList: billDetails,
        };
      } else {
        return {
          ...state,
          orderList: [],
        };
      }
    case "FETCH_DATA_REQUEST":
      console.log(state.isGetting);
      return {
        ...state,
        isGetting: true,
      };
    case "FETCH_DATA_FAILURE":
      return {
        ...state,
        error: true,
        isGetting: false,
      };
    case "FETCH_DATA_SUCCESS":
      return {
        ...state,
        error: false,
        isGetting: false,
      };

    default:
      return state;
  }
};
export default CartReducer;
