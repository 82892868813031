import React, { useEffect, useState } from "react";
import "./Cart.css";
import { ImBin2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  DeleteAddress,
  DeleteItem,
  GetAddress,
  GetCartlist,
  SetDeliveryAddress,
} from "../../Action/Index";
import { Button, Loader } from "../../Components/uiElements/UIElements";
import { getUser } from "../../LoginUtils/LoginUtils";

function Cart() {
  const { isGetting } = useSelector((state) => state.CartReducer);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStore = useStore();

  const [ui, setUi] = useState(0);
  const [ammount, setAmmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  const [cartData, setcartData] = useState(null);
  const [addressList, setAddressList] = useState(null);
  const [address, setAddress] = useState(null);
  const [isEmpty, setIsEmpty] = useState(true);

  // useEffect(() => {
  //   dispatch(GetCartlist());
  // }, [dispatch]);=
  console.log(cartData);

  setTimeout(() => {
    const cart = reduxStore.getState().CartReducer.list;
    const addressData = reduxStore.getState().AddressReducer.addressList[0];
    try {
      if (cart === null) {
        console.log(cart);
        const error = new Error("Error in receaving data");
        throw error;
      }
      setcartData(cart);
      let totalAmmount = 0;
      let discount = 0;
      let oldPrice = 0;
      let valueT = 0;
      // let dic = 0;
      cartData != null && isGetting === false
        ? cartData?.details?.forEach((val) => {
            valueT += val?.product_qty * val?.product?.product_price;

            // dic += val?.product_qty * val?.product?.product_oldprice;
            oldPrice += val?.product_qty * val?.product?.product_oldprice;
          })
        : // setLoading(false)
          console.log("cartData is Empty");

      discount = oldPrice - valueT;
      setAmmount(valueT);
      valueT < 5000 && valueT > 0
        ? setAmmount(valueT + 200)
        : console.log("delivery free");
      setDiscount(discount);
      setOldPrice(oldPrice);
    } catch (error) {
      setcartData([]);
      console.log(error);
    }

    // Checking for the addressList Data (empty or not)
    try {
      if (addressData != null) {
        setAddressList(addressData);
      } else {
        const error = new Error("Error in receaving data");
        throw error;
      }
    } catch (error) {
      setAddressList([]);
      console.log(error);
    }
    // console.log(totalAmmount, oldPrice, discount);
  }, 1000);

  const handelCheckout = () => {
    if (address === null) dispatch(SetDeliveryAddress(addressList[0]));
    navigate("/user/checkout");
  };

  useEffect(() => {
    dispatch(GetAddress());
  }, [cartData]);

  useEffect(() => {
    setIsEmpty(false);
  }, [addressList]);

  useEffect(() => {
    dispatch(GetCartlist());
    dispatch(GetAddress());
    setIsEmpty(false);
  }, [ui]);
  // console.log(isGetting);
  return isGetting ? (
    // return cartData == null && addressList == null ? (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ width: "100vw", height: "80vh" }}
    >
      <Loader size={"5rem"} />
      {/* <h1>Cart is empty</h1> */}
    </div>
  ) : (
    <div className="container-fluid cartContainer position-relative">
      <div className="cart container-fluid position-relative">
        {/* header Strat */}
        <div className="header row p-2  ">
          <h1 style={{ fontSize: "2rem" }}>Shopping Cart</h1>
          <div className="row px-0 container">
            <div className="col-6 text-left">Home / Shop / Cart</div>
            <div className="col-6 text-right">
              {" "}
              No. of items:{cartData?.count ? cartData?.count : "Not Available"}
            </div>
          </div>
        </div>

        {/* HeaderEnd */}
        {cartData === null ? (
          <div>
            <Loader size={"2rem"} />
          </div>
        ) : (
          <>
            {cartData === false ? (
              <div className="cart_emptyCartDiv">Your Cart Is Empty</div>
            ) : (
              cartData.details?.map((ele, index) => (
                <div key={index} id={ele.id ? ele.id : "Not Available"}>
                  <hr />
                  <div className=" row py-2 ">
                    <ul className="container ">
                      <li className="container-fluid px-0">
                        <div className="row container p-3 list">
                          <div className="col-2 productImage d-none d-md-flex p-0">
                            <img
                              style={{ borderRadius: "25px" }}
                              src={ele.product?.images[0]?.product_image}
                              alt=""
                            />
                          </div>
                          <div className=" col-6 col-md-3  container productDescription">
                            <h3>
                              {" "}
                              {ele?.product?.product_name
                                ? ele?.product.product_name
                                : "Not Available"}
                            </h3>

                            <p style={{ textAlign: "justify" }}>
                              {" "}
                              {ele.product?.product_desc
                                ? ele.product.product_desc.slice(0, 100) + "..."
                                : "Not Available"}
                            </p>
                          </div>
                          <div className="functionality col-6 d-flex justify-content-around align-items-center">
                            {" "}
                            <div className=" col-4 productQuantity d-flex flex-column">
                              {" "}
                              <h6 className="pt-2 d-flex flex-row">
                                <span style={{ color: "var(--color-bg" }}>
                                  {" "}
                                  Quantity:
                                </span>{" "}
                                <span
                                  className="mx-2"
                                  style={{ fontWeight: "400" }}
                                >
                                  {ele?.product_qty}
                                </span>
                              </h6>
                              <h6 className="pt-2 d-flex flex-row">
                                <span style={{ color: "var(--color-bg" }}>
                                  {" "}
                                  Price:
                                </span>{" "}
                                <span
                                  className="mx-2"
                                  style={{ fontWeight: "400" }}
                                >
                                  &#8377;{ele?.product?.product_price}
                                </span>
                              </h6>
                              <h6 className="pt-2 d-flex flex-row">
                                <span style={{ color: "var(--color-bg" }}>
                                  {" "}
                                  Total:
                                </span>{" "}
                                <span
                                  className="mx-2"
                                  style={{ fontWeight: "400" }}
                                >
                                  &#8377;
                                  {ele?.product_qty *
                                    ele?.product?.product_price}
                                </span>
                              </h6>
                            </div>
                            <div className="cancel col-1 ">
                              <ImBin2
                                style={{ fontSize: "1.3rem" }}
                                onClick={() => {
                                  dispatch(
                                    DeleteItem(ele?.product_id, getUser())
                                  );
                                  setUi((ui + 1) % 2);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <hr />
                </div>
              ))
            )}
          </>
        )}
        <div className="checkout row d-flex flex-row-reverse justify-content-between p-3 checkoutContainer">
          <div className="summary col-md-4 px-4 my-2">
            <ul
              className="mb-0 d-flex flex-column py-2 mt-4"
              style={{ gap: ".5rem" }}
            >
              <li
                className="total d-flex justify-content-between my-2"
                style={{ width: "80%" }}
              >
                Price
                <span>&#8377;{oldPrice}</span>
              </li>
              <li
                className="d-flex justify-content-between my-2"
                style={{ width: "80%" }}
              >
                Discount <span>&#8377;{discount}</span>
              </li>
              <li
                className="d-flex justify-content-between my-2"
                style={{ width: "80%" }}
              >
                Delivery
                <span>{ammount < 5000 && ammount >= 1 ? "200" : "Free"}</span>
              </li>
              <li
                className="total d-flex justify-content-between my-2"
                style={{ width: "80%" }}
              >
                <strong> Total</strong>
                <span>
                  <strong>
                    {/* {ammount < 5000 ? ammount + 200 : ammount}&#8377; */}
                    &#8377;{ammount}
                  </strong>
                </span>
              </li>

              <li
                className="total d-flex justify-content-between my-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  style={{
                    backgroundColor:
                      cartData === false ||
                      cartData === null ||
                      cartData?.count === 0 ||
                      addressList?.length === 0
                        ? "#18a4ae7c"
                        : "#18A3AE",
                    cursor:
                      cartData === false ||
                      cartData === null ||
                      cartData?.count === 0 ||
                      addressList?.length === 0
                        ? "no-drop"
                        : "pointer",
                  }}
                  onClick={() => handelCheckout()}
                  disabled={
                    cartData === false ||
                    cartData === null ||
                    cartData?.count === 0 ||
                    addressList?.length === 0
                  }
                >
                  Checkout
                </Button>
              </li>
              <li className="total d-flex justify-content-between my-2">
                Free Shipping on order above &#8377; 5000
              </li>
            </ul>
          </div>
          <div
            className=" col-md-6 deliveryAdd d-flex justify-content-left flex-column p-3 my-2 mx-0 "
            style={{
              boxShadow: " 0 2px 7px #dfdfdf",
              height: "fit-content",
              borderRadius: "25px",
            }}
          >
            <h3 className="text-left">Delivery Address</h3>
            {addressList !== null && addressList?.length === 0 ? (
              <>Please add new address before going to checkout</>
            ) : (
              <>
                {addressList !== null &&
                addressList?.length > 0 &&
                address === null ? (
                  <p className="text-left">
                    {addressList[0]?.full_address}, {addressList[0]?.city},
                    {addressList[0]?.state}, {addressList[0]?.pincode}
                  </p>
                ) : (
                  <p className="text-left">{address}</p>
                )}
              </>
            )}

            {addressList?.map((ele, idx) => {
              return (
                <div
                  key={idx}
                  class="container-fluid my-2 p-3"
                  style={{
                    border: "1px rgb(214, 214, 214) solid",
                    borderRadius: "10px",
                    boxShadow:
                      "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                  }}
                >
                  <div class="container">
                    <div class="container text-left d-flex flex-column justify-content-between align-items-left">
                      <div
                        class="d-flex flex-row justify-content-between"
                        style={{ fontSize: ".8rem" }}
                      >
                        <input
                          defaultChecked={idx === 0}
                          type="radio"
                          className="mx-4"
                          name="address"
                          value={""}
                          onClick={(e) => {
                            dispatch(SetDeliveryAddress(ele));
                            console.log(e?.target?.checked, address);
                            return e?.target?.checked
                              ? setAddress(
                                  `${ele?.full_address}, ${ele?.city}, ${ele?.state}, ${ele?.pincode}`
                                )
                              : "";
                          }}
                        />

                        <div className="container-fluid d-flex flex-row p-0 justify-content:between">
                          <div class="pr-4">
                            <span>
                              <span>
                                {`${ele?.full_address} ${ele?.city} ${ele?.state}-`}

                                <span class="_2dQV-8">{ele?.pincode}</span>
                              </span>
                            </span>
                          </div>
                          <div className="cancel d-flex align-items-center">
                            {" "}
                            <ImBin2
                              onClick={() => {
                                dispatch(DeleteAddress(ele?.id));
                                setUi((ui + 1) % 2);
                              }}
                              style={{ fontSize: ".8rem" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="container d-flex justify-content-center">
              {" "}
              <Button
                style={{
                  width: "fit-content",
                }}
                onClick={() => {
                  navigate("/form");
                }}
              >
                New Address
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
