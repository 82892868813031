import React from "react";
import Invoice from "../../Components/Invoice/Invoice";
import "./InvoicePage.css";

function InvoicePage() {
  return (
    <div>
      <Invoice />
    </div>
  );
}

export default InvoicePage;
