import React, { useState } from "react";
import { BsCart4, BsExclamationLg } from "react-icons/bs";
import { FaCheck, FaTruckMoving } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import "./PreviousOrderCard.css";
import { Button } from "../uiElements/UIElements";
import { redirect, useNavigate } from "react-router-dom";
import axios from "axios";

const PreviousOrderCard = (props) => {
  const [invoiceLink, setInvoiceLink] = useState(null);
  const { order, key, date, totalAmount } = props;
  const navigate = useNavigate();

  const [orderStatus, setOrderStatus] = useState(order.status);
  async function downloadInvoice(id) {
    await axios
      .get(
        `https://paydeer.in/shop/Backend/public/api/generate-pdf?order_id=${id}`
      )
      .then((res) => setInvoiceLink(res.data))
      .catch((err) => console.log(err));
  }

  invoiceLink != null ? redirect(invoiceLink) : console.log("kj");

  const showMessage = () => {
    switch (orderStatus) {
      case 0:
        return "Initiated";
      case 1:
        return "In-progress";
      case 2:
        return "In-Transit";
      case 3:
        return "Delivered";
      case 4:
        return "Cancel";
      default:
        return "Error in featching";
    }
  };
  const showOrderStatusImage = () => {
    switch (orderStatus) {
      case 0:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "#18A3AE" }}
          >
            <BsCart4 size={25} color="white" />
          </div>
        );
      case 1:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "#FEAF62" }}
          >
            <BsCart4 size={25} color="white" />
          </div>
        );
      case 2:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "#FEAF62" }}
          >
            <FaTruckMoving size={25} color="white" />
          </div>
        );
      case 3:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "green" }}
          >
            <FaCheck size={25} color="white" />
          </div>
        );
      case 4:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "red" }}
          >
            <ImCross size={25} color="white" />
          </div>
        );
      default:
        return (
          <div
            key={props.key}
            className="previousOrderPage__order_image"
            style={{ backgroundColor: "red" }}
          >
            <BsExclamationLg size={25} color="white" />
          </div>
        );
    }
  };
  return (
    <div key={props.key} className="previousOrderPage__order">
      <div className="previousOrderPage__order_header">
        <div>
          <p
            style={{
              fontWeight: "600",
              fontSize: "1rem",
              marginBottom: "5px",
            }}
          >
            ORDER PLACED
          </p>
          <p style={{ marginBottom: "0" }}>{date}</p>
        </div>
        <div className="d-flex flex-column">
          <div>
            <p style={{ marginBottom: "0" }}>ORDER # {order.id}</p>
            {/* <p>{order.created_at}</p> */}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/order-detail/${order.id}`, {
                state: { order: order },
              })
            }
          >
            View Order Details
          </div>
        </div>
      </div>
      <div className="previousOrderPage__order_body">
        <div className="previousOrderPage__order_body-top">{showMessage()}</div>
        <div className="previousOrderPage__order_body-mid">
          {/* <div className="previousOrderPage__order_image">
                <BsCart4 size={25} color="white" />
            </div> */}
          {showOrderStatusImage()}
          <div className="previousOrderPage__order_details">
            <div>{order.name}</div>
            <div>Total: {order.order_item.length}</div>
            <div>Amount: {totalAmount}</div>
            <div style={{ textAlign: "left" }}>
              Delivary Address:
              <span style={{ wordWrap: "break-word" }}>
                {order.address}, {order.city}, {order.state},{order.pincode}
              </span>
              <div className="downloadInvoice">
                {/* {invoiceLink && console.log(invoiceLink)} */}
                {/* {invoiceLink ? (
                  window.location.replace(invoiceLink.data)
                ) : ( */}
                {/* <Button> */}

                {/* <a */}
                {/* //     id="download "
                  //     href={invoiceLink.data}
                  //     style={{ textDecoration: "none", color: "white" }}
                  //   >
                  //     Your Invoice Ready */}
                {/* //   </a> */}
                {/* // </Button> */}
                <Button
                  id="downloadPdf"
                  onClick={() => {
                    // navigate(`/invoice`, {
                    //   state: { order: order },
                    // });
                    downloadInvoice(order.id);
                  }}
                >
                  Download Invoice
                </Button>
                {/* )} */}
                {invoiceLink && window.location.replace(invoiceLink.data)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousOrderCard;
