import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadingPage,
} from "../../Components/uiElements/UIElements";
import ReduxStore from "../../ReduxStore/ReduxStore";
import { useEffect } from "react";
import "./PreviousOrders.css";
import PreviousOrderCard from "../../Components/PreviousOrderCard/PreviousOrderCard";
import { GetOrderDetails } from "../../Action/Index";
// import { Button } from 'bootstrap';

const PreviousOrders = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.OrderDetailsReducer);
  const [orders, setOrders] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const getData = () => {
    setTimeout(() => {
      const orderData = ReduxStore.getState().OrderDetailsReducer.orders;
      setOrders(orderData);
      setPageLoading(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(GetOrderDetails("GET_ALL"));
    getData();
  }, []);
  
  const getTotalCost = (orderDetails) => {
    let cost = 0;
    orderDetails.order_item.forEach((element) => {
      cost += element.price * element.qty;
    });
    return cost;
  };
  const getDate = (timestamp) => {
    const date = new Date(timestamp);
    const dateString =
      date.getDate().toString() +
      "/" +
      date.getMonth().toString() +
      "/" +
      date.getFullYear().toString();
    return dateString;
  };

  // useEffect(()=>{
  //     const orderData = ReduxStore.getState().CartReducer.orders;
  //     setOrders(orderData);
  // },[])

  return (
    <div className="previousOrderPage">
      {isLoading || pageLoading ? (
        <LoadingPage size={"5rem"} />
      ) : (
        <div className="container">
          {/* Top headings */}
          <div className="previousOrderPage__header container-fluid position-relative">
            <div className="header row p-2  ">
              <h1 style={{ fontSize: "2rem" }}>Previous Orders</h1>

              <div className="row px-0 container">
                <div className="col-6 text-left">Home / Previous Order</div>
                <div className="col-6 text-right">
                  {" "}
                  No. of orders: {orders?.length}
                </div>
              </div>
            </div>
          </div>
          {/* Top headings Ends */}
          {/* Order Listing Start*/}
          <div className="previousOrderPage__orderList">
            {orders?.map((order, idx) => (
              <PreviousOrderCard
                order={order}
                key={idx}
                date={getDate(order.created_at)}
                totalAmount={getTotalCost(order)}
              />
            ))}
          </div>
          {/* Order Listing Ends */}
        </div>
      )}
    </div>
  );
};

export default PreviousOrders;
