import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import ReduxThunk from "redux-thunk";

import RootReducer from "../Reducers/Index";

const composedEnhancer = compose(
  // it useful to pass multiple middleware in redux store to inhance application
  applyMiddleware(ReduxThunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const ReduxStore = createStore(RootReducer, composedEnhancer);
console.log(ReduxStore);
export default ReduxStore;
