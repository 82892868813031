export const getUser = () => {
  //Will get user from session storage if it is aleardy registered
  const userStr = sessionStorage.getItem("user_id");
  // if (userStr) return JSON.parse(userStr);
  // else return null;
  return userStr;
};
export const getUserMobile = () => {
  //Will get user from session storage if it is aleardy registered
  const userMobile = sessionStorage.getItem("mobile");
  if (userMobile) return JSON.parse(userMobile);
  else return null;
};
export const getUserToken = () => {
  const userToken = localStorage.getItem("Token");
  return userToken;
};

export const genrateOtp = (otp) => {
  return sessionStorage.setItem("otp", otp); // sets the user details in session storage
};
export const setUserSession = (mobile, user_id, userName, Token) => {
  sessionStorage.setItem("mobile", mobile); // sets the user details in session storage
  sessionStorage.setItem("user_id", user_id);
  sessionStorage.setItem("userName", userName);
  // sessionStorage.setItem("otpGenrated", value);
  sessionStorage.setItem("Token", Token);
  localStorage.setItem("Token", Token);
};
export const setUserName = (name) => {
  sessionStorage.setItem("userName", name);
};
export const getUserName = () => {
  return sessionStorage.getItem("userName") || null;
};
export const removeUserSession = () => {
  sessionStorage.removeItem("mobile"); // remove data from storage
  sessionStorage.removeItem("user_id");
  sessionStorage.removeItem("otpGenrated");
  sessionStorage.removeItem("otp");
  sessionStorage.removeItem("Token");
  sessionStorage.removeItem("userName");
  localStorage.removeItem("Token");
  localStorage.removeItem("userName");
  localStorage.removeItem("user_id");
};
