import axios from "axios";

const initialData = {
  addressList: [],
  isAddressAdded: false,
  deliveryAddress: "",
};
const AddressReducer = (state = initialData, action) => {
  switch (action.type) {
    case "ADDRESS_ADD":
      const { addressResponse } = action.payload;

      return {
        ...state,
        isAddressAdded: addressResponse.status,
      };

    //   break;
    case "GET_ADDRESS":
      const { userAddress } = action.payload;

      return {
        ...state,
        addressList: [userAddress?.data.data],
      };
    case "SET_PRIMARY":
      const { primaryAddress } = action.payload;

      return {
        ...state,
        deliveryAddress: primaryAddress,
      };
    case "DELETE_ADDRESS":
      const { delete_add_id } = action.payload;

      axios
        .post("https://paydeer.in/shop/Backend/public/api/addressDelete", {
          id: delete_add_id,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });

      state.addressList = state.addressList.filter((val) => {
        return val.id !== delete_add_id;
      });

      return {
        ...state,
        addressList: state.addressList,
      };

    default:
      return state;
  }
};
export default AddressReducer;
