import { getUser } from "../LoginUtils/LoginUtils";

const initialData = {
  userId: getUser(),
  location: {
    lan: null,
    lon: null,
  },
};
const UserReducer = (state = initialData, action) => {
  switch (action.type) {
    case "GET_USER_ID":
      console.log("-------I am in here user Reducer---------");
      const userID = action.payload;
      console.log(userID);
      return { ...state, userId: userID };
    case "SET_LOCATION":
      console.log("-------I am in here user Reducer---------");
      console.log(action.payload);
      const { lon, lat } = action.payload;
      const newLoaction = {
        lon: lon,
        lat: lat,
      };
      return {
        ...state,
        location: newLoaction,
      };

    default:
      return { state };
  }
};

export default UserReducer;
