import React from "react";
import "./Footer.css";
import { BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import { TfiYoutube } from "react-icons/tfi";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";

function Footer() {
  return (
    <footer className="footer container-fluid d-flex flex-column">
      {/* <div className="form-border"></div> */}

      <div>
        {" "}
        <div className="container py-4 d-flex flex-column flex-md-row justify-content-between">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Paydeer Services Pvt Ltd</span>
            </strong>
            . All Rights Reserved
            <br />
            <p>
              <a href="https://paydeer.in/refund-policy">Refund Policy</a> |
              <a href="https://paydeer.in/privacy-policy">Privacy policy</a> |
              <a href="https://paydeer.in/cookies-policy">Cookies Policy</a>
            </p>
          </div>
          <div className="credits">
            {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/Paydeer-bootstrap-business-template/ --> */}
            Designed by <a href="https://paydeer.in/">Paydeer.in</a>
            <div
              id="social-links"
              className="align-items-center m-3 d-flex justify-content-center"
              style={{ gap: "1rem", color: "white" }}
            >
              <a
                href="https://twitter.com/paydeerindia"
                className="socialIcon"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="iconFooter" />
              </a>
              <a
                href="https://www.facebook.com/Paydeer.business"
                target="_blank"
                className="socialIcon"
                rel="noreferrer"
              >
                <FaFacebookF className="iconFooter" />
              </a>
              <a
                href="https://in.pinterest.com/paydeer/"
                className="socialIcon"
                target="_blank"
                rel="noreferrer"
              >
                <FaPinterestP className="iconFooter" />
              </a>
              <a
                href="https://www.instagram.com/Paydeer.business/"
                className="socialIcon"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="iconFooter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCW01CsZteJbZKRExzYLJsnQ"
                className="socialIcon"
                target="_blank"
                rel="noreferrer"
              >
                <TfiYoutube className="iconFooter" />
              </a>
              <a
                href="https://www.linkedin.com/company/paydeer"
                className="socialIcon"
                target="_blank"
                rel="noreferrer"
              >
                <BsLinkedin className="iconFooter" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
