import { ThreeDots } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import "./UIElements.css";

export const Loader = ({ size }) => {
  return (
    <div className="uiElement_loader" style={{ width: size, height: size }} />
  );
};

export const Button = (props) => {
  const { children, onClick, type, style, disabled } = props;
  return (
    <button
      className="uiElement_button"
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const LoadingPage = ({ size }) => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ width: "100vw", height: "80vh" }}
    >
      {" "}
      <Loader size={size} />
    </div>
  );
};

export const NavUI = (props) => {
  const { 
    onClick, 
    children,
    path
  } = props;
  return (
    <NavLink
      style={{ textDecoration: "none" }}
      to={path}
      className="linkTag"
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};
