
const initialData = {
    orders: {},
    singleOrderDetail: {},
    isLoading: false,
};
const OrderDetailsReducer = (state = initialData, action)=>{
    switch(action.type){
        case "LATEST_ORDER_DETAILS":
            const orderDetail = action.payload
            console.log(orderDetail)
            return{
                ...state,
                singleOrderDetail: orderDetail,
                isLoading: false,
            }
        case "ALL_ORDER_DETAILS":
            const allOrderDetails = action.payload
            return{
                ...state,
                orders: allOrderDetails,
                isLoading: false
            }
        case "FETCH_DATA_REQUEST":
            return {
                ...state,
                isLoading: true,
            };
        default:
            return{
                state
            }
    }
}

export default OrderDetailsReducer;