import React from 'react'
import "./ErrorPage.css"

const ErrorPage = (props) => {

    // const {
    //     status,
    //     message,
    // } = props;
    return (
        <div className='errorPage'>
            <div className='errorPage__status'>
                404
            </div>
            <div className='errorPage__message'>
                Some thing went wrong
            </div>
            <div className='errorPage__button'>
                Back to home page
            </div>
        </div>
    )
}

export default ErrorPage