import React, { useEffect, useState } from "react";
import ProductCard from "../../Components/ProductCard/ProductCard";
import NewProductCard from "../../Components/ProductCard/newProductCard";
import "./Product.css";

import axios from "axios";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { LoadingPage } from "../../Components/uiElements/UIElements";
import UserAddress from "../../Components/UserAddress/UserAddress";

function Product(props) {
  const [productdata, setProductdata] = useState(null);
  // const list = useSelector((state) => state.CartReducer.list); // to get data from the store

  useEffect(() => {
    axios
      .get("https://paydeer.in/shop/Backend/public/api/fetchProducts")
      .then(function (response) {
        // handle success

        setProductdata(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);
  useEffect(() => {});
  

  return (
    <div
      className="productCardContainer"
      onScroll={(e) => {
        console.log(e.currentTarget.scrollTop);
      }}
    >
      {!productdata ? (
        <LoadingPage size={"5rem"} />
      ) : (
        productdata.map((element, index) => {
          return (
            <NewProductCard
              setShowModal={props.setShowModal}
              key={index}
              id={element.id}
              name={element.product_name}
              desc={element.product_desc}
              image={element.images}
              price={element.product_price}
              oldPrice={element.product_oldprice}
              stockTotal={element.stock_total}
              inStock={element.instock}
            />
          );
        })
      )}
    </div>
  );
}

export default Product;
