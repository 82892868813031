import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../LoginUtils/LoginUtils";

function PrivateRoutes({ Component }) {
  const auth = getUser();
  return auth ? <Outlet /> : <Navigate to="/" />;
  // return <Outlet />;
}

export default PrivateRoutes;
