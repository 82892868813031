import React, { useEffect, useState } from "react";
import {useSelector } from "react-redux";
import "./ConfirmOrder.css";
import { useLocation, useNavigate} from "react-router-dom";
import { LoadingPage } from "../../Components/uiElements/UIElements";

const ConfirmOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading } = useSelector((state) => state.OrderDetailsReducer);
  const [orderDetail, setOrderDetail] = useState(location.state.order);
  const [date, setDate] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [totalItems, settotalItems] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const showOrderStatus = (orderStatus) => {
    switch (orderStatus) {
      case 0:
        return "Initiate";
      case 1:
        return "In-progress";
      case 2:
        return "In-Transit";
      case 3:
        return "Delivered";
      case 4:
        return "Cancel";
      default:
        return "Error in featching";
    }
  };
  const showMessage = (orderStatus) => {
    switch (orderStatus) {
      case 0:
        return (
          <div className="orderDetailsPage__conformation">
            <h1>Order Processing</h1>
            <p>We are working on your order.</p>
            {/* <img src="" alt="SuccessImage"></img> */}
          </div>
        );
      case 1:
        return (
          <div className="orderDetailsPage__conformation">
            <h1>Order Confirm</h1>
            <p>We are working on your order.</p>
            {/* <img src="" alt="SuccessImage"></img> */}
          </div>
        );
      case 2:
        return (
          <div className="orderDetailsPage__conformation">
            <h1>Order Confirm</h1>
            <p>We are working on your order.</p>
            {/* <img src="" alt="SuccessImage"></img> */}
          </div>
        );
      case 3:
        return (
          <div className="orderDetailsPage__conformation">
            <h1>Order Delivered</h1>
            <p>Thank you for shoping with us</p>
          </div>
        );
      case 4:
        return (
          <div className="orderDetailsPage__conformation">
            <h1 style={{ color: "red" }}>Cancel</h1>
            <p>Sorry for the inconvinious</p>
            {/* <img src="" alt="SuccessImage"></img> */}
          </div>
        );
      default:
        return <>Error</>;
    }
  };
  console.log(orderDetail);

  useEffect(() => {
    const date = new Date(orderDetail?.created_at);
    const dateString =
      date.getDate().toString() +
      "/" +
      date.getMonth().toString() +
      "/" +
      date.getFullYear().toString();
    console.log(dateString);
    setDate(dateString);
    let cost = 0;
    let num = 0;

    orderDetail?.order_item.forEach((element) => {
      num += parseInt(element.qty);
      cost += parseInt(element.qty) * parseInt(element.price);
    });
    setOrderDetail(orderDetail);
    setTotalCost(cost);
    settotalItems(num);
    setIsPageLoading(false);
  }, []);

  if (isPageLoading || isLoading) {
    return <LoadingPage />;
  }
  return (
    <div className="orderDetailsPage">
      <div className="orderDetailsPage__right">
        <div>{showMessage(orderDetail.status)}</div>
        <div className="orderDetailsPage__userDetails">
          <h3>Order Details</h3>
          <div className="container">
            <div style={{marginBottom:"1rem"}}>
              <div className="row align-items-center">
                <div className="col orderDetailsPage__userDetails_heading">
                  Name
                </div>
                <div className="col orderDetailsPage__userDetails_heading">
                  Order id
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">{orderDetail?.name}</div>
                <div className="col">{orderDetail?.id}</div>
              </div>
            </div>
            {/* <br /> */}
            <div style={{marginBottom:"1rem"}}>
              <div className="row align-items-center">
                <div className="col orderDetailsPage__userDetails_heading">
                  Total Cost
                </div>
                <div className="col orderDetailsPage__userDetails_heading">
                  Total Items
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">{totalCost}</div>
                <div className="col">{totalItems}</div>
              </div>
            </div>
            {/* <br /> */}
            <div style={{marginBottom:"1rem"}}>
              <div className="row align-items-center">
                <div className="col orderDetailsPage__userDetails_heading">
                  Phone Number
                </div>
                <div className="col orderDetailsPage__userDetails_heading">
                  Order Placed on
                </div>
              </div>
              <div className="row align-items-top">
                <div className="col">{orderDetail?.phone}</div>
                <div className="col">{date}</div>
              </div>
            </div>
            {/* <br /> */}
            <div style={{marginBottom:"1rem"}}>
              <div className="row align-items-center">
                <div className="col orderDetailsPage__userDetails_heading">
                  Status
                </div>
                <div className="col orderDetailsPage__userDetails_heading">
                  Pin Code
                </div>
              </div>
              <div className="row align-items-top">
                <div className="col">{showOrderStatus(orderDetail.status)}</div>
                <div className="col">{orderDetail?.pincode}</div>
              </div>
            </div>
            {/* <br /> */}
            <div className="row align-items-center ">
              <div className="col orderDetailsPage__userDetails_heading">
                Delivary Address
              </div>
            </div>
            <div className="row align-items-top ">
              <div className="orderDetailsPage__userDetails_address">
                {orderDetail?.address}, {orderDetail?.city},{" "}
                {orderDetail?.state}
              </div>
            </div>
          </div>
        </div>
        <div className="button" onClick={() => navigate("/")}>
          Continue Shopping
        </div>
      </div>
      <div className="orderDetailsPage__productsList">
        {orderDetail?.order_item.map((order_item, key) => (
          <div id={key} className="orderDetailsPage__product">
            <div className="col-6 orderDetailsPage__productImage">
              <img
                style={{ borderRadius: "25px" }}
                src={order_item.product?.images[0]?.product_image}
                alt=""
              />
            </div>
            <div className="container orderDetailsPage__productDetails">
              <h3>
                {" "}
                {order_item.product?.product_name
                  ? order_item.product.product_name
                  : "Not Available"}
              </h3>

              <p>
                {" "}
                {order_item.product?.product_desc
                  ? order_item.product.product_desc.slice(0, 100) + "..."
                  : "Not Available"}
              </p>
              <p>
                {" "}
                {order_item?.qty
                  ? `Quantity: ${order_item.qty}`
                  : "Not Available"}
              </p>
              <p>
                {" "}
                {order_item?.price
                  ? `Cost: ${order_item.qty * order_item.price}Rs`
                  : "Not Available"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfirmOrder;
