import React, { useEffect } from "react";
import "./LoginModal.css";
import PaydeerLogo from "../../Assets/PaydeerNewLogo.png";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Loader } from "../../Components/uiElements/UIElements";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function LoginModal(props) {
  const [location, setLocation] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleMobileNumChange = (event) => {
    props.setMobileNum(event.target.value);
  };

  const handlePasswordChange = (event) => {
    props.setPassword(event.target.value);
  };
  const handleOtpChange = (event) => {
    props.setOtp(event.target.value);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position.coords.latitude, position.coords.longitude);
          props.setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  location ? console.log(location) : console.log("mmm");
  console.log(props.otpSent);
  console.log(props);

  return (
    <Modal show={props.show} onHide={props.handleClose} backdrop="static">
      {props.error === true ? (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Oops!</strong> Please check mobile number and password
          again....!
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : (
        console.log("your mobile and password are right")
      )}
      {props.OtpError === true ? (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Oops!</strong> Please check your otp again....!
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : (
        console.log("your mobile and password are right")
      )}
      {/* {props.isLoading === true ? (
        <div className="alert alert-primary" role="alert">
          Loading....!
        </div>
      ) : (
        console.log("your mobile and password are right")
      )} */}
      <div id="card-title">
        <div
          className="cancelbutton px-4"
          style={{ width: "100%", display: "flex", justifyContent: "end" }}
        >
          <span onClick={() => props.setShowModal(!props.show)}>X</span>
        </div>
        <a className="navbar-brand  mr-auto" href="/">
          <img
            src={PaydeerLogo}
            alt="logo"
            style={{ width: "100px", height: "50px" }}
          />
        </a>
      </div>
      <Modal.Body>
        <div id="card-content">
          {/* <form method="post" onSubmit={props.handleLogin}> */}
          {/* <form method="post" className="form" onSubmit={props.handleLogin}> */}

          <div className="form">
            <label htmlFor="user-email" style={{ paddingTop: "13px" }}>
              &nbsp;Phone No.
            </label>
            <input
              id="user-email"
              className="form-content"
              type="number"
              name="number"
              placeholder=" Enter Register Number"
              value={props.mobileNum}
              onChange={handleMobileNumChange}
              autoComplete="on"
              required
            />
            <div className="form-border"></div>
            <label htmlFor="user-password" style={{ paddingTop: "22px" }}>
              &nbsp;Password
            </label>
            {/* <div className="form-content"> */}
            <div className="password-wrapper">
              <input
                id="user-password"
                className="form-content"
                maxlength="10"
                // className="form-content"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={props.password}
                onChange={handlePasswordChange}
                required
              />
              {!showPassword ? (
                <AiFillEyeInvisible
                  className="passwordEye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <AiFillEye
                  className="passwordEye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            <div className="form-border"></div>
            <label
              htmlFor="user-otp"
              style={{
                paddingTop: "22px",
                display: props.otpSent ? "block" : "none",
              }}
            >
              &nbsp;OTP
            </label>
            <input
              id="user-otp"
              className="form-content"
              type="password"
              name="otp"
              placeholder="Enter otp"
              value={props.otp}
              onChange={handleOtpChange}
              style={{ display: props.otpSent ? "block" : "none" }}
              required
            />
            <div className="form-border"></div>
            <a href="https://paydeer.in/Agent/Login.php">
              <legend id="forgot-pass">Forgot password?</legend>
            </a>
            <button
              id="submit-btn"
              className="d-flex flex-row justify-content-center align-items-center"
              type="submit"
              name="submit"
              value={props.otpSent ? "Login" : "Get OTP"}
              onClick={props.handleLogin}
            >
              <span className="mx-3 text-white">
                {" "}
                {props.otpSent ? "Login" : "Get OTP"}
              </span>{" "}
              <span>
                {props.isLoading === true ? <Loader size={"1rem"} /> : ""}
              </span>
            </button>
            <a href="https://paydeer.in/welcome" id="signup">
              Don't have account yet?
            </a>
          </div>
          {/* </form> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
