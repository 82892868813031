import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { GetOrderDetails } from "../../Action/Index";
// import { GetAddress, GetCartlist, GetOrderDetails } from "../../Action/Index";
import { useDispatch, useSelector } from "react-redux";
import { GrMail } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import {
  BsArrowLeftCircle,
  BsFillTelephoneFill,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";
import PaydeerLogo from "../../Assets/PaydeerNewLogo.png";
import { NavUI } from "../uiElements/UIElements";
import { FaFacebookF, FaPinterestP } from "react-icons/fa";
import { TfiYoutube } from "react-icons/tfi";
import {
  AiOutlineHistory,
  AiOutlineShopping,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  getUser,
  getUserName,
  removeUserSession,
  setUserSession,
} from "../../LoginUtils/LoginUtils";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function Navbar({ showModal, setShowModal, setOtpSent }) {
  const [showNavbar, setshowNavbar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [classToggle, setClassToggle] = useState(true);
  const [userName, setUserName] = useState(false);
  const [cartLength, setCartLength] = useState(0);
  const { length, isGetting, cartLengthh } = useSelector(
    (state) => state.CartReducer
  );

  useEffect(() => {
    getUserName() != null
      ? setUserName(sessionStorage.getItem("userName"))
      : console.log("i have got user name");
    if (length !== null) setCartLength(length);
    console.log("updating Length", length);
    // if(length===0)setCartLength(0)
  }, [length, isGetting]);

  useEffect(() => {
    const options = {
      url: "https://paydeer.in/shop/Backend/public/api/checkTokenWeb",
      method: "POST",
      headers: {
        token: `${localStorage.getItem("Token")}`,
      },
    };
    axios(options)
      .then((res) => {
        // const persons = res.data;
        console.log(res);
        setUserName(res.data.data.userName);
        setUserSession(
          // res.response,

          // res.data.user_id,
          res.status,
          res.data.data.user_id,
          res.data.data.userName,

          res.data.data.token
        ); // setting details in session storage
        sessionStorage.setItem("userName", res.data.data.userName);
        // localStorage.setItem("Token", res.data.token);
      })
      .catch((err) =>
        err.response.data.response == 500
          ? removeUserSession()
          : console.log(err)
      );
  }, []);

  return (
    <div style={{ position: "sticky", top: "0", zIndex: "10" }}>
      <div
        className="containe-fluid d-flex align-items-center "
        style={{ background: "#18A3AE", padding: " .2rem 6rem" }}
      >
        <div className="contact-info mr-auto d-flex align-items-center">
          <GrMail style={{ color: "white" }} />
          <a
            href="mailto:info@paydeer.in"
            className="px-2 "
            style={{ color: "white" }}
          >
            info@paydeer.in
          </a>
          <BsFillTelephoneFill style={{ color: "white" }} />
          <a href="tel:7428274282" className="px-2 " style={{ color: "white" }}>
            7428274282
          </a>
        </div>
        <div
          id="social-links"
          className="align-items-center "
          style={{ gap: "1rem", color: "white" }}
        >
          <a
            href="https://twitter.com/paydeerindia"
            className="twitter"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter className="socialIcon" />
          </a>
          <a
            href="https://www.facebook.com/Paydeer.business"
            target="_blank"
            className="facebook"
            rel="noreferrer"
          >
            <FaFacebookF className="socialIcon" />
          </a>
          <a
            href="https://in.pinterest.com/paydeer/"
            className="pinterest"
            target="_blank"
            rel="noreferrer"
          >
            <FaPinterestP className="socialIcon" />
          </a>
          <a
            href="https://www.instagram.com/Paydeer.business/"
            className="instagram"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram className="socialIcon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCW01CsZteJbZKRExzYLJsnQ"
            className="youtube"
            target="_blank"
            rel="noreferrer"
          >
            <TfiYoutube className="socialIcon" />
          </a>
          <a
            href="https://www.linkedin.com/company/paydeer"
            className="linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin className="socialIcon" />
          </a>
          <a
            href="/"
            onClick={showModal}
            className={
              !getUser() ? "btn-buy py-1 px-4 mx-2 font-weight-bold" : "d-none"
            }
            style={{
              fontSize: ".8rem",
              background: "white",
              borderRadius: "5px",
              color: "rgb(24, 163, 174)",
              border: "none",
            }}
          >
            Login
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.india.paydeer "
            target="_blank"
            className="btn-buy p-1 px-2 mx-2 font-weight-bold"
            download=""
            style={{
              fontSize: ".8rem",
              background: "white",
              borderRadius: "5px",
              color: "rgb(24, 163, 174)",
            }}
          >
            Download App
          </a>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-light bg-light py-3"
        style={{
          boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)",
          marginBottom: "1rem",
          backgroundColor: "white",
        }}
      >
        <a className="navbar-brand  mr-auto" href="/">
          <img
            src={PaydeerLogo}
            alt="logo"
            style={{ width: "150px", height: "50px" }}
          />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setshowNavbar(!showNavbar)}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          style={{ display: `${showNavbar ? "block" : "none"}` }}
        >
          <ul
            className="navbar-nav  ml-auto "
            style={{
              borderRadius: "1rem",
              background: "#f8f9fa",
              position: "absolute",
              right: "2rem",
              lineHeight: "3rem",
            }}
          >
            <li
              className="nav-item active d-flex justify-content-center align-items-center"
              style={{ color: "black" }}
            >
              <NavUI path={"https://paydeer.in/"} style={{ color: "black" }}>
                <BsArrowLeftCircle /> Paydeer Home
              </NavUI>
            </li>

            <li className="nav-item d-flex justify-content-center align-items-center">
              <NavUI path={"/"}>
                <AiOutlineShopping /> Product
              </NavUI>
            </li>

            <li className="nav-item d-flex justify-content-center align-items-center">
              <NavLink
                style={{ textDecoration: "none" }}
                to={!getUser() ? "/" : "/user/cart"}
                className="linkTag"
                onClick={() => {
                  !getUser() ? setShowModal(true) : console.log("please login");
                  // return dispatch(GetAddress());
                }}
              >
                <span className="cartIcon__container">
                  <AiOutlineShoppingCart style={{ zIndex: "10" }} />
                  {!isGetting &&
                    // <span className="cartLength__container">

                    // </span>
                    // <span className="cartLength__container">{cartLength}</span>
                    (cartLengthh != 0 ? (
                      <span className="cartLength__container">
                        {cartLengthh}
                      </span>
                    ) : (
                      ""
                    ))}
                </span>
                Cart
              </NavLink>
            </li>

            <li className="nav-item d-flex justify-content-center align-items-center">
              <NavUI
                path={getUser() ? "/user/previous-orders" : "/"}
                // path={"/previous-orders"}
                onClick={() => {
                  !getUser() ? setShowModal(true) : console.log("please login");
                  dispatch(GetOrderDetails("GET_ALL"));
                }}
              >
                <AiOutlineHistory /> History
              </NavUI>
            </li>

            <li>
              <div className="profile">
                {/* <ulclassNameName="navbar-nav  ml-auto ">
                  <li
                   classNameName="nav-item d-flex justify-content-center align-items-center"
                    style={{
                      color: "black",
                      listStyle: "none",
                      listStyleType: "none",
                    }}
                  > */}

                {/* >  */}

                {/* {getUser() ? (
                  <a
                    href="#"
                   classNameName="display-picture linkTag"
                    onClick={() => setClassToggle(!classToggle)}
                  >
                    <CgProfile
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "100",
                        margin: "0 .5rem 0 0",
                      }}
                      onClick={() => setClassToggle(!classToggle)}
                    /> */}
                {userName ? (
                  // {getUser() ? (
                  <a
                    href="#"
                    className="display-picture linkTag"
                    onClick={() => setClassToggle(!classToggle)}
                  >
                    <CgProfile
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "100",
                        margin: "0 .5rem 0 0",
                      }}
                      onClick={() => setClassToggle(!classToggle)}
                    />

                    {"Hey " + userName}
                    {/* {"Hey " + getUserName()} */}
                  </a>
                ) : (
                  console.log("first login")
                )}
                {/* </ul> */}
                <div className={!classToggle ? "cardProfile " : "hidden"}>
                  <ul
                    id="logout"
                    style={{
                      listStyle: "none",
                      listStyleType: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/");
                      removeUserSession();
                      setUserName(false);
                      setShowModal(true);
                      setClassToggle(!classToggle);
                      setOtpSent(false);
                    }}
                  >
                    {/* <a style={{ cursor: "pointer" }}> */}
                    {/* <span> Log Out</span> */}
                    {/* </a> */}
                    Log Out
                    {/* </li> */}
                  </ul>
                </div>
                {/* <-----------------------------------------> */}
                {/* </li>
                </ul> */}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
