import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Product from "./Pages/Product/Product";
import Cart from "./Pages/Cart/Cart";
import AddressForm from "./Pages/AddressForm/AddressForm";
import Checkout from "./Pages/Checkout/Checkout";
import ConfirmOrder from "./Pages/Confirm/ConfirmOrder";
import Admin from "./Pages/Admin/Admin";
import PreviousOrders from "./Pages/PreviousOrders/PreviousOrders";
import Confirmation from "./Pages/Confirm/Confirmation";
import LoginModal from "./Pages/LoginModal/LoginModal";
import { useState } from "react";
import {
  genrateOtp,
  getUserToken,
  setUserName,
  setUserSession,
} from "./LoginUtils/LoginUtils";
import axios from "axios";
import { GetCartlist } from "./Action/Index";
import { useDispatch } from "react-redux";
import ReduxStore from "./ReduxStore/ReduxStore";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import Invoice from "./Components/Invoice/Invoice";
import { useRef } from "react";
import jsPDF from "jspdf";
import { Await } from "react-router-dom";
import InvoicePage from "./Pages/InvoicePage/InvoicePage";
import Footer from "./Components/Footer/Footer";
import ProductDetails from "./Pages/ProductDetails/ProductDetails";
import PrivateRoutes from "./Routes/PrivateRoutes";
import { useEffect } from "react";
// import Invoice from "./Components/Invoice/Invoice";
// import { useRef } from "react";
// import jsPDF from "jspdf";
// import { Await } from "react-router-dom";
// import InvoicePage from "./Pages/InvoicePage/InvoicePage";

function App() {
  const dispatch = useDispatch();
  const len = ReduxStore.getState().CartReducer.length;
  const [userLocation, setUserLocation] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [error, setError] = useState(false);
  const [OtpError, setOtpError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const [mobileNum, setMobileNum] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(null);
  useEffect(() => {
    getUserToken() ? setShowModal(false) : setShowModal(true);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(false);
    if (userLocation === null) {
      alert("Please allow access location");
    }
    if (
      sessionStorage.getItem.user == null &&
      userLocation !== null &&
      !getUserToken()
    ) {
      const response = await axios
        .post("https://paydeer.in/shop/Backend/public/api/sendOtp", {
          mobile: `${mobileNum}`,
          password: `${password}`,
          lati: `${userLocation.latitude}`,
          long: `${userLocation.longitude}`,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.response);
          setError(false);
          setIsLoading(false);
          return res;
        })
        .catch((err) => {
          console.log(err);
          setOtpSent(false);
          setError(true);
          setIsLoading(false);
          return err;
        });

      if (response.data.response === 200) {
        setOtpSent(true);
        genrateOtp(true);
      } else {
        setOtpSent(false);
        setError(true);
        setIsLoading(false);
      }
    }
    if (otp !== "" && genrateOtp() != false && !getUserToken()) {
      setIsLoading(true);
      const varifyOtp = await fetch(
        "https://paydeer.in/shop/Backend/public/api/verifyOtp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: `${mobileNum}`, otp: `${otp}` }),
        }
      );

      const varifyResponse = await varifyOtp.json();
      console.log(varifyResponse);
      if (varifyResponse.response === 200) {
        setOtp("");
        setPassword("");
        setIsLoading(false);
        setUserName(varifyResponse.data.userName);

        setUserSession(
          mobileNum,
          varifyResponse.data.user_id,
          varifyResponse.data.userName,
          varifyResponse.data.token
        ); // setting details in session storage
        dispatch(GetCartlist());
        setShowModal(false);
      } else {
        setIsLoading(false);
        // setError(true);
        setOtpError(true);
      }
    }
  };

  return (
    <div className="App">
      {sessionStorage.getItem("user_id") == null ? (
        <LoginModal
          show={showModal}
          setShowModal={setShowModal}
          handleLogin={handleSubmit}
          mobileNum={mobileNum}
          setMobileNum={setMobileNum}
          setOtp={setOtp}
          otpSent={otpSent}
          password={password}
          setPassword={setPassword}
          otp={otp}
          error={error}
          isLoading={isLoading}
          setUserLocation={setUserLocation}
          OtpError={OtpError}
        />
      ) : (
        console.log("user already logged")
      )}
      <Navbar
        showModal={handleShowModal}
        setShowModal={setShowModal}
        setOtpSent={setOtpSent}
      />
      {/* <div>
        <button className="button" onClick={handleGeneratePdf}>
          Generate PDF
        </button>
        <div ref={reportTemplateRef}>
          <Invoice />
        </div>
      </div> */}

      <Routes>
        <Route
          exact
          path="/"
          element={<Product setShowModal={setShowModal} />}
        />
        {/* <Route exact path="/product/:productId" element={<ProductDetails />} /> */}
        <Route exact path="/invoice" element={<InvoicePage />} />
        {/* <Route exact path="/cart" element={<Cart />} /> */}
        <Route exact path="/form" element={<AddressForm />} />

        {/* <Route
          exact
          path="/checkout"
          element={<Checkout props={setShowModal} />}
        /> */}
        <Route
          exact
          path="/order-confirm"
          element={<Confirmation status={true} />}
        />
        <Route
          exact
          path="/order-detail/:orderId"
          element={<ConfirmOrder status={true} />}
        />
        <Route
          exact
          path="/order-not-confirm"
          element={<ConfirmOrder status={false} />}
        />
        {/* <Route exact path="/previous-orders" element={<PreviousOrders />} /> */}
        <Route exact path="/dashboard" element={<Admin />} />
        <Route exact path="/error-page-not-found" element={<ErrorPage />} />
        <Route path="/user" element={<PrivateRoutes />}>
          <Route path="cart" element={<Cart />} />

          <Route path="previous-orders" element={<PreviousOrders />} />

          <Route path="checkout" element={<Checkout props={setShowModal} />} />

          <Route path="product/:productId" element={<ProductDetails />} />
        </Route>
      </Routes>
      <hr className="p-0 m-0" />
      <Footer />
    </div>
  );
}

export default App;
