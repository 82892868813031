import React, { useState } from "react";
import "./ProductDetails.css";
import { LoadingPage, Button } from "../../Components/uiElements/UIElements";
import axios from "axios";
import { useEffect } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import paydeerAssureLogo from "../../Assets/paydeerAssure.svg";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { useDispatch, useSelector, useStore } from "react-redux";
import ReduxStore from "../../ReduxStore/ReduxStore";
import { AddToCart, DeleteItem, GetCartlist } from "../../Action/Index";
import { getUser } from "../../LoginUtils/LoginUtils";

const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const cart = reduxStore.getState().CartReducer.list;

  const [productDetails, setProductDetails] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [productQuantity, setProductQuantity] = useState(0);
  const [error, setError] = useState(null);

  // setTimeout(()=>{
  //     console.log(cart)
  // },1000);
  const fetchProductDetails = () => {
    axios
      .post("https://paydeer.in/shop/Backend/public/api/productDetail", {
        id: productId,
      })
      .then((responce) => {
        console.log(responce);
        if (responce.data.data != null) {
          setProductDetails(responce.data.data);
          setSelectedImg(responce.data.data.images[0].product_image);
        } else {
          // alert("NULL")
          navigate("/");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handelSelectImage = (imgLink) => {
    setSelectedImg(imgLink);
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);
  return (
    <div className="productDetailsPage">
      {!productDetails ? (
        <LoadingPage size="5rem" />
      ) : (
        <div className="productDetail">
          {/* Images container */}
          <div className="productDetail__productImages">
            <div className="productDetail__productImages__container">
              {productDetails?.images?.map((data) => {
                return (
                  <div className="imgContainer">
                    <img
                      className="productDetail__productImages__image"
                      src={data.product_image}
                      alt="abc"
                      onMouseEnter={() => handelSelectImage(data.product_image)}
                      onClick={() => handelSelectImage(data.product_image)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="productDetail__productImages__largeImage">
              <img
                className="productDetail__productImages__image"
                src={selectedImg}
                alt="abc"
              />
            </div>
          </div>

          {/* Details container */}
          <div className="productDetail__text">
            <div className="productDetail__text__name">
              {productDetails.product_name}
            </div>
            <div className="productDetail__text__name">
              <img
                src={paydeerAssureLogo}
                alt="paydeer Assureed"
                className="productDetail__text__icon"
              />
            </div>

            <div className="productDetail__text__price">
              &#8377; {productDetails.product_price}
              <small>&#8377; {productDetails.product_oldprice}</small>
              <span>
                {Math.ceil(
                  ((productDetails.product_oldprice -
                    productDetails.product_price) /
                    productDetails.product_oldprice) *
                    100
                )}
                % off
              </span>
            </div>
            <div className="productDetail__text__description">
              {productDetails.product_desc}
            </div>
            <div className="productDetail__add__container position-relative">
              {/* If the  user add product without selecting quantity */}
              {error != null && (
                <span
                  className="errorMessage "
                  style={{
                    position: "absolute",
                    left: "4px",
                    top: "-20px",
                    width: "max-content",
                  }}
                >
                  {error}
                </span>
              )}
              <div className="productDetail__add quantity">
                <button
                  className="decre"
                  //   disabled={productQuantity === 0}
                  style={{
                    cursor: productQuantity <= 0 ? "no-drop" : "pointer",
                    borderRadius: "100%",
                  }}
                  onClick={() => {
                    if (productQuantity === 0) {
                      setError("Please add product");
                      setTimeout(() => {
                        setError("");
                      }, 1000);
                    } else {
                      setProductQuantity(productQuantity - 1);
                      if (productQuantity === 0) {
                        dispatch(DeleteItem(productId, getUser()));
                        dispatch(GetCartlist());
                      } else {
                        dispatch(AddToCart(productId, -1));
                        dispatch(GetCartlist());
                      }
                    }
                  }}
                >
                  <AiOutlineMinusCircle />
                </button>
                <span>{productQuantity}</span>
                <button
                  className="incre"
                  style={{
                    cursor: productQuantity >= 50 ? "no-drop" : "pointer",
                  }}
                  // disabled={productQuantity >= 50}
                  onClick={() => {
                    if (productDetails.stock_total <= productQuantity) {
                      setError("Product out of Stock");
                      setTimeout(() => {
                        setError("");
                      }, 1000);
                    } else if (productQuantity >= 50) {
                      setError("Maximum limit reached");
                      setTimeout(() => {
                        setError("");
                      }, 1000);
                    } else {
                      dispatch(AddToCart(productId, 1));
                      dispatch(GetCartlist());
                      setProductQuantity(productQuantity + 1);
                    }
                  }}
                >
                  <BsPlusCircle />
                </button>
              </div>
              {/* <Button>

                            </Button> */}
            </div>
          </div>
        </div>
      )}
      {/* <div className='moreProducts'>

        </div> */}
    </div>
  );
};

export default ProductDetails;
