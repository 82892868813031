import React, { useEffect, useRef, useState } from "react";
import "./AddressForm.css";
import { useDispatch, useSelector, useStore } from "react-redux";
import { SetAddress } from "../../Action/Index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdOutlineDoneAll } from "react-icons/md";

import swal from "sweetalert";
import { Button, Loader } from "../../Components/uiElements/UIElements";
import { getUser } from "../../LoginUtils/LoginUtils";

function AddressForm() {
  const navigate = useNavigate();
  const addressFormRef = useRef([]);
  const dispatch = useDispatch();
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [addressAdded, setAddressAdded] = useState(false);

  const store = useStore();

  console.log(store.getState());

  const getState = () => {
    setState(addressFormRef.current[3]?.value); //gtting State Name from HTML options
  };

  useEffect(() => {
    setCity(null);
    axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: "India",
        state: `${state}`,
      })
      .then((response) => {
        setCity(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setAddressAdded(false);
    let address = {
      user_name: addressFormRef.current[0]?.value,
      email: addressFormRef.current[1]?.value,
      user_id: getUser(), // initial userId
      full_address: `${addressFormRef.current[2]?.value}`,
      state: `${addressFormRef.current[3]?.value}`,
      city: `${addressFormRef.current[5]?.value}`,
      pincode: addressFormRef.current[4]?.value,

      // primary_address: 0, //by default 0 rom server
      primary_address: addressFormRef.current[6].checked ? 1 : 0, //by default 0 rom server
    };
    dispatch(SetAddress(address));
    const handleOnClick = () => {
      setAddressAdded(true);
      setTimeout(() => {
        let value = store.getState().AddressReducer.isAddressAdded;
        // value === 200 ? setAddressAdded(true) : setAddressAdded(false);
        if (value === 200) {
          addressFormRef.current = [];

          setTimeout(() => {
            navigate("/user/cart");
          }, 2000);
          // return swal({
          //   title: "Good job!",
          //   text: "Address has been added",
          //   icon: "success",
          // });
        } else {
          setAddressAdded(false);
          // return swal({
          //   title: "Add Something!",
          //   text: "Check your details again!",
          //   icon: "error",
          // });
        }
      }, 3000);
    };
    return handleOnClick();
  };

  return (
    <div className=" formContainer">
      <div>
        {" "}
        <div
          style={{
            // lineHeight: "3rem",
            borderBottom: "1px solid black",
          }}
        >
          {" "}
          <h4 className="mb-3">Billing Address</h4>
        </div>
        <form className="needs-validation" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col mb-3">
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="FullName"
                ref={(key) => (addressFormRef.current[0] = key)}
                required
              />

              <div className="invalid-feedback">
                Valid first name is required.
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="username">Email</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Email</span>
              </div>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                ref={(key) => (addressFormRef.current[1] = key)}
                required
              />
              <div className="invalid-feedback" style={{ width: "100%" }}>
                Your username is required.
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              placeholder="Enter address"
              ref={(key) => (addressFormRef.current[2] = key)}
              required
            />
            <div className="invalid-feedback">
              Please enter your shipping address.
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="state">State</label>
              <select
                className="custom-select d-block w-100"
                id="state"
                ref={(key) => (addressFormRef.current[3] = key)}
                onChange={getState}
                required
              >
                <option value="">Choose...</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>

                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadar and Nagar Haveli">
                  Dadar and Nagar Haveli
                </option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
              <div className="invalid-feedback">
                Please provide a valid state.
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="city">City</label>
              <select
                className="custom-select d-block w-100"
                id="city"
                ref={(key) => (addressFormRef.current[5] = key)}
                required
                style={{ minWidth: "15rem" }}
              >
                {city != null ? (
                  city.map((ele, index) => {
                    return (
                      <option id={index} value={ele}>
                        {ele}
                      </option>
                    );
                  })
                ) : (
                  <option value="">Loading...</option>
                )}
              </select>
              <div className="invalid-feedback">
                Please provide a valid City.
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="pinCode">Pin Code</label>
              <input
                type="text"
                className="form-control"
                id="pinCode"
                placeholder=""
                ref={(key) => (addressFormRef.current[4] = key)}
                required
              />
              <div className="invalid-feedback">Pin code required.</div>
            </div>
          </div>
          <hr className="mb-4" />

          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="save-info"
              // ref={newRef}
              ref={(key) => (addressFormRef.current[6] = key)}
              // onChange={(e) => console.log(e.target.checked)}
            />
            <label className="custom-control-label" htmlFor="save-info">
              Set this address as primary address
            </label>
          </div>

          {/* <button className="btn btn-primary btn-lg btn-block" type="submit">
            Add Address
          </button> */}
          <Button type="submit">
            Add Address
            {addressAdded && (
              <span className="ml-3">
                <Loader size={"1rem"} />
              </span>
            )}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AddressForm;
