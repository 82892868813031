import React, { useEffect, useState } from "react";
import "./Checkout.css";
import ReduxStore from "../../ReduxStore/ReduxStore";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetCartlist } from "../../Action/Index";
import checkout_gif from "../../Assets/checkout_gif.svg";
import axios from "axios";
import {
  Button,
  Loader,
  LoadingPage,
} from "../../Components/uiElements/UIElements";
import {
  getUser,
  getUserMobile,
  getUserName,
  removeUserSession,
} from "../../LoginUtils/LoginUtils";

const Checkout = ({ props }) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [openBox, setOpenBox] = useState(0);
  const [address, setAddress] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [cartData, setCartData] = useState(null);
  const [otp, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [qrImage, setQRImage] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    dispatch(GetCartlist());
  }, []);

  setTimeout(() => {
    const primaryAddress = ReduxStore.getState().AddressReducer.deliveryAddress;
    setAddress(primaryAddress);
    // console.log("Address---->",address);
    const cartData = ReduxStore.getState().CartReducer.list;
    if (cartData != null && cartData.count > 0) {
      let total = 0;
      let cost = 0;
      cartData.details.forEach((data, idx) => {
        total += data.product_qty;
        cost += data.product_qty * data.product.product_price;
      });
      setCartData(cartData);
      setTotalCost(cost);
      cost =
        totalCost + totalCost * 0.18 > 5000
          ? totalCost + totalCost * 0.18
          : totalCost + totalCost * 0.18 + 200;
      setGrandTotal(cost);
      setTotalItems(total);
      setLoading(false);
    }
  }, 2000);

  //Send OTP
  const sendOTP = async () => {
    setShowSpinner(true);
    let fd = new FormData();
    // fd.append("userId", 6);
    // fd.append("mainAmount", 200);
    fd.append("userId", getUser());
    fd.append("mainAmount", totalCost);
    await axios
      .post("https://paydeer.in/shop/Backend/public/api/mainWalletSendOTP", fd)
      .then((response) => {
        console.log(totalCost);
        // alert("Working");
        console.log(response);
        console.log(response.data.response);
        switch (response.data.response) {
          case "200":
            alert("OTP has been sent");
            setMessage("OTP send on registered mobile number.");
            setOpenBox(3);
            break;

          case "400":
            const amountInWallet = parseInt(response.data.data.balQR.walletBal);
            const remaining = grandTotal - amountInWallet;
            if (response.data.data.balQR.QR != null)
              setQRImage(response.data.data.balQR.QR);
            setMessage("Please add " + remaining + "Rs in your wallet");
            alert(response.data.message);
            console.log(`data:image/jpeg;base64, ${qrImage}`);
            break;
          // const amountInWallet = response.data.response.data.walletBal;
          // const remaining = totalCost - amountInWallet;
          // if (response.data.response.data.QR != null)
          //   setQRImage(response.data.response.data.QR);
          // setMessage("Please add " + remaining + "Rs in your wallet");
          // alert(response.data.response.message);
          // console.log(`data:image/jpeg;base64, ${qrImage}`);
          // break;

          case "401":
            setMessage("Sorry try login again");
            alert("Sorry try login again");
            removeUserSession();
            props(true);
            navigate("/");
            break;

          default:
            setMessage("Error in featching payment");
            alert("Error in featching payment");
            break;
        }
      })
      .catch((error) => {
        console.log(totalCost);
        console.log(getUser());

        alert("Error in payment processing");
        console.log("error", error);
        switch (error.data.response.status) {
          case 401:
            setMessage("Sorry try login again");
            alert("Sorry try login again");
            removeUserSession();
            props(true);
            navigate("/");
            break;

          default:
            setMessage("Error in featching payment");
            alert("Error in featching payment");
            break;
        }
      });
    setShowSpinner(false);
  };

  //Place order api
  const placeOrder = async () => {
    await axios
      .post("https://paydeer.in/shop/Backend/public/api/placeOrder", {
        user_id: getUser(),
        name: getUserName(),
        phone: getUserMobile(),
        address: address.full_address.toString(),
        city: address.city.toString(),
        state: address.state.toString(),
        pincode: address.pincode.toString(),
        status: 0,
      })
      .then((response) => {
        console.log(response);
        if (response.status) {
          console.log("Success");
          navigate("/previous-orders");
          // navigate("/order-confirm");
          // navigate(`/order-confirm`, {
          //   state: { order: "kk", address: "nk" },
          // });
          // navigate(`/order-confirm`, {
          //   state: { order: response.data.cart_item.items, address: address },
          // });
        } else {
          alert("Order Not placed");
        }
      })
      .catch((error) => {
        setShowSpinner(false);
        console.log(error);
        alert(error);
      });
    setShowSpinner(false);
  };

  const varifyOTP = async () => {
    setLoading(true);
    let fd = new FormData();
    fd.append("mainAmount", totalCost);
    fd.append("userId", getUser());
    fd.append("otp", otp);
    await axios
      .post(
        "https://paydeer.in/shop/Backend/public/api/mainWalletVerifyOTP",
        fd
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.response === 200) {
          alert("Payment Success");
          placeOrder();
        } else {
          alert("OTP not Match");
        }
      })
      .catch(() => {
        alert("Otp not match");
      });
    // placeOrder();
  };

  return (
    <div className="checkoutPage">
      {loading ? (
        <LoadingPage size={"5rem"} />
      ) : (
        <div className="checkoutPage__container">
          <div className="checkoutPage__boxs">
            <div className="checkoutPage__box checkoutPage__boxTitle_bottomRadius">
              <div className="checkoutPage__boxTitle">Address</div>
              {openBox === 0 && (
                <div className="checkoutPage__boxDetails">
                  <div>{address.full_address}</div>
                  <div>
                    {address.city} {address.state} {address.pincode}
                  </div>
                  <div className="checkoutPage__buttonContainer">
                    <Button
                      onClick={() => {
                        navigate("/user/cart");
                      }}
                    >
                      Change
                    </Button>
                    <Button onClick={() => setOpenBox(1)}>Confirm</Button>
                  </div>
                </div>
              )}
            </div>
            <br />
            <div className=" checkoutPage__box checkoutPage__boxTitle_bottomRadius">
              <div className="checkoutPage__boxTitle">Order Summary</div>
              {openBox === 1 && (
                <div className="checkoutPage__boxDetails">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData.details.map((details, idx) => (
                        <tr key={idx}>
                          <td>{details.product.product_name}</td>
                          <td>{details.product_qty}</td>
                          <td>
                            {details.product_qty *
                              details.product.product_price}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <th>Total</th>
                        <td>{totalItems}</td>
                        <td>{totalCost}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-right w-100 d-flex justify-content-end">
                    <table className="text-right w-50 ">
                      <tbody>
                        <tr>
                          <th>Tax(18%)</th>
                          <td>{Math.round(totalCost * 0.18)}</td>
                        </tr>
                        <tr>
                          <th>Shipping</th>
                          <td>
                            {totalCost + Math.round(totalCost * 0.18) > 5000
                              ? 0
                              : 200}
                          </td>
                        </tr>
                        <tr>
                          <th>Grand Total</th>
                          <td>
                            {totalCost + Math.round(totalCost * 0.18) > 5000
                              ? totalCost + Math.round(totalCost * 0.18)
                              : totalCost + Math.round(totalCost * 0.18) + 200}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="checkoutPage__buttonContainer">
                    <Button onClick={() => setOpenBox(0)}>Back</Button>
                    <Button onClick={() => setOpenBox(2)}>Confirm</Button>
                  </div>
                </div>
              )}
            </div>
            <br />
            <div className=" checkoutPage__box checkoutPage__boxTitle_bottomRadius">
              <div className="checkoutPage__boxTitle">Payment Mode</div>
              {openBox >= 2 && (
                <div className="checkoutPage__boxDetails">
                  <div>
                    {openBox === 2 ? (
                      <div>
                        <strong>{grandTotal}&#8377;</strong> will be deducted
                        from your paydeer Wallet
                        <br />
                        <br />
                        {message?.length > 0 && <strong>{message}</strong>}
                      </div>
                    ) : (
                      <div>
                        {message}
                        <br />
                        <br />
                        <input
                          value={otp}
                          onChange={(e) => {
                            e.preventDefault();
                            setOTP(e.target.value);
                          }}
                          className="checkoutPage__input"
                          placeholder="Please Enter OTP"
                          type="number"
                        />
                      </div>
                    )}
                  </div>
                  {openBox === 2 && (
                    <div className="checkoutPage__buttonContainer">
                      <Button
                        onClick={() => {
                          setOpenBox(1);
                          setMessage("");
                        }}
                      >
                        Back
                      </Button>

                      <Button onClick={() => sendOTP()}>
                        Pay{" "}
                        {showSpinner && (
                          <span style={{ marginLeft: "5px" }}>
                            <Loader size="16px" />
                          </span>
                        )}
                      </Button>
                    </div>
                  )}
                  {openBox === 3 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={() => varifyOTP()}
                        disabled={otp.length === 0}
                      >
                        Verify OTP
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {qrImage?.length !== 0 ? (
            <div className="qrCodeContainer">
              <img
                className="qrCodeContainer__img"
                src={qrImage}
                alt="qrimage"
              />
              <div className="qrCodeContainer__text">
                Scan QR to add money in wallet.
              </div>
            </div>
          ) : (
            <img
              className="qrCodeContainer__img"
              src={checkout_gif}
              // src="https://source.unsplash.com/400x500/?nature"
              alt="Image"
              style={{ marginBottom: "0" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Checkout;
