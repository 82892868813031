import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GetOrderDetails } from "../../Action/Index";
import ReduxStore from "../../ReduxStore/ReduxStore";

import "./ConfirmOrder.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "../../Components/uiElements/UIElements";
import { getUser } from "../../LoginUtils/LoginUtils";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [orderDetail, setOrderDetail] = useState(null);

  const [address, setAddress] = useState(null);

  // const [orderDetail, setOrderDetail] = useState(location.state.order);

  // const [address, setAddress] = useState(location.state.address);
  const [date, setDate] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [totalItems, settotalItems] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const { orderID } = useParams();
  console.log(orderDetail, address);
  let value = null;

  useEffect(async () => {
    await axios
      .post("https://paydeer.in/shop/Backend/public/api/orderDetail", {
        user_id: getUser(),
      })
      .then((response) => {
        console.log(response);

        if (response.status) {
          console.log("Success");
          setOrderDetail(response.data.data);
          value = response;
          console.log(value.data.data);

          // navigate("/order-confirm");
          // navigate(`/order-confirm`, {
          //   state: { order: "kk", address: "nk" },
          // });
          // navigate(`/order-confirm`, {
          //   state: { order: response.data.cart_item.items, address: address },
          // });
        } else {
          alert("Something Went Wrong");
        }
      })
      .catch((error) => {
        // setShowSpinner(false);
        console.log(error);
        alert(error);
      });
    // setShowSpinner(false);
  }, []);

  // useEffect(() => {
  //   const date = new Date(orderDetail[0]?.created_at);
  //   const dateString =
  //     date.getDate().toString() +
  //     "/" +
  //     date.getMonth().toString() +
  //     "/" +
  //     date.getFullYear().toString();
  //   console.log(dateString);
  //   setDate(dateString);
  //   let cost = 0;
  //   let num = 0;
  //   orderDetail?.forEach((element) => {
  //     num += parseInt(element.product_qty);
  //     cost +=
  //       parseInt(element.product_qty) * parseInt(element.product.product_price);
  //   });
  //   // setTotalCost(cost);
  //   // settotalItems(num);
  //   setTotalCost(orderDetail[0]?.total);
  //   settotalItems();
  //   setIsPageLoading(false);
  // }, [orderDetail]);

  return (
    <div className="orderDetailsPage">
      <div className="orderDetailsPage__right">
        <div className="orderDetailsPage__conformation">
          <h1>Order Confirm</h1>
          <p>Thank you for shoping with us</p>
          {/* <img src="" alt="SuccessImage"></img> */}
        </div>
        <div className="orderDetailsPage__userDetails">
          <h3>Order Details</h3>
          <div className="container">
            <div className="row align-items-center">
              <div className="col orderDetailsPage__userDetails_heading">
                Name
              </div>
              <div className="col orderDetailsPage__userDetails_heading">
                Order id
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col">{orderDetail[0]?.name}</div>
              <div className="col">{orderDetail[0]?.id}</div>
            </div>
            <br />
            <div className="row align-items-center">
              <div className="col orderDetailsPage__userDetails_heading">
                Total Cost
              </div>
              <div className="col orderDetailsPage__userDetails_heading">
                Total Items
              </div>
            </div>
            <div className="row align-items-center">
              {/* <div className="col">{totalCost}</div>
              <div className="col">{totalItems}</div> */}
            </div>
            <br />
            <div className="row align-items-center">
              <div className="col orderDetailsPage__userDetails_heading">
                Phone Number
              </div>
              <div className="col orderDetailsPage__userDetails_heading">
                Order Placed on
              </div>
            </div>
            <div className="row align-items-top">
              {/* <div className="col">{orderDetail[0]?.mobile}</div> */}
              <div className="col">{date}</div>
            </div>
            <br />
            <div className="row align-items-center">
              <div className="col orderDetailsPage__userDetails_heading">
                Status
              </div>
              <div className="col orderDetailsPage__userDetails_heading">
                Pin Code
              </div>
            </div>
            <div className="row align-items-top">
              {/* <div className="col">Order Receaved</div>
              <div className="col">{address?.pincode}</div> */}
            </div>
            <br />
            <div className="row align-items-center ">
              <div className="col orderDetailsPage__userDetails_heading">
                Delivary Address
              </div>
            </div>
            <div className="row align-items-top ">
              <div className="">
                {/* {`${address.full_address}, ${address.city}, ${address.state}`} */}
              </div>
            </div>
          </div>
        </div>
        <div className="button" onClick={() => navigate("/")}>
          Continue Shopping
        </div>
      </div>
      <div className="orderDetailsPage__productsList">
        {/* {orderDetail?.map((order_item, key) => (
          <div id={key} className="orderDetailsPage__product">
            <div className="col-6 orderDetailsPage__productImage">
              <img
                style={{ borderRadius: "25px" }}
                src={order_item.product?.images[0]?.product_image}
                alt=""
              />
            </div>
            <div className="container orderDetailsPage__productDetails">
              <h3>
                {" "}
                {order_item.product?.product_name
                  ? order_item.product.product_name
                  : "Not Available"}
              </h3>

              <p>
                {" "}
                {order_item.product?.product_desc
                  ? order_item.product.product_desc.slice(0, 100) + "..."
                  : "Not Available"}
              </p>
              <p>
                {" "}
                {order_item?.product_qty
                  ? `Quantity: ${order_item.product_qty}`
                  : "Not Available"}
              </p>
              <p>
                {" "}
                {order_item.product?.product_price
                  ? `Cost: ${
                      order_item.product_qty * order_item.product.product_price
                    }Rs`
                  : "Not Available"}
              </p>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default Confirmation;
