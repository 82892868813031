import React, { useState } from "react";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsPlusCircle } from "react-icons/bs";
import "./ProductCard.css";
import { useDispatch, useSelector, useStore } from "react-redux";
import { AddToCart, DeleteItem, GetCartlist } from "../../Action/Index";
import { getUser } from "../../LoginUtils/LoginUtils";
import { Loader } from "../uiElements/UIElements";
import checkmark from "../../Assets/checkmark.gif";
import ReduxStore from "../../ReduxStore/ReduxStore";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import { useEffect } from "react";
import { useNavigate } from "react-router";
import { GrFormPrevious, GrNext, GrPrevious } from "react-icons/gr";

const NewProductCard = (props) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [productQuantity, setProductQuantity] = useState(0);
  const [userLogin, setUserLogin] = useState(false);
  const [error, setError] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productAdded, setProductAdded] = useState(false);
  let random = Math.random();
  const handelAddToCart = () => {
    if (!getUser()) {
      props.setShowModal(true);
      navigator("/");
    } else {
      if (productQuantity === 0) {
        setProductQuantity(1);
        setLoading(true);
        dispatch(AddToCart(props.id, 1));
        dispatch(GetCartlist());
        setIsAdded(true);
        setTimeout(() => {
          setLoading(false);
          setProductAdded(true);
        }, 3000);
      } else {
        setProductQuantity(0);
        setLoading(true);
        dispatch(DeleteItem(props.id, getUser()));
        dispatch(GetCartlist());
        setIsAdded(true);
        setTimeout(() => {
          setLoading(false);
          setProductAdded(true);
        }, 3000);
      }
    }
  };

  return (
    <div className="productCard" key={random}>
      <div
        className="productCart__image__container"
        onClick={() => navigator(`/user/product/${props.id}`)}
      >
        <div
          // id="carouselExampleIndicators"
          id={random}
          class="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner ">
            <div id="imgOne" className="carousel-item active">
              <img
                className="d-block w-100"
                src={props.image[0]?.product_image}
                // src="https://source.unsplash.com/400x300/?gym"
                alt="First slide"
              />
            </div>
            <div id="imgTwo" className="carousel-item">
              <img
                className="d-block w-100"
                src={props.image[1]?.product_image}
                // src="https://source.unsplash.com/400x300/?actor"
                alt="Second slide"
              />
            </div>
            <div className="carousel-item" id="imgThree">
              <img
                className="d-block w-100"
                src={props.image[2]?.product_image}
                // src="https://source.unsplash.com/400x300/?actor,girl"
                alt="Third slide"
              />
            </div>
          </div>
          <a
            className="carousel-control-prev "
            href={`#${random}`}
            role="button"
            data-slide="prev"
          >
            <FaAngleLeft className="iconF" />
          </a>
          <a
            className="carousel-control-next "
            href={`#${random}`}
            role="button"
            data-slide="next"
          >
            <FaAngleRight className="iconF" />
          </a>
        </div>
      </div>
      <div className={props.inStock === 0 ? "outOfStock" : "d-none"}>
        <div className="outOfStock-backgroound"></div>
        <div className="outOfStock-div">Out of Stock</div>
      </div>
      <div className="productCart__details">
        <div
          className="productCart__details__title mt-3"
          style={{ zIndex: "5" }}
          onClick={() => navigator(`/user/product/${props.id}`)}
        >
          {props.name}
        </div>
        <div
          className="productCart__details__dect  text-secondary"
          onClick={() => navigator(`/user/product/${props.id}`)}
        >
          {props.desc.slice(0, 50) + "..."}
        </div>
        <div className="productCart__details__events">
          <div className="productCart__details__cost">
            <span>&#8377; {props.oldPrice}</span>
            &#8377; {props.price}
          </div>
          <div className="productCart__details__add position-relative">
            {/* If the  user add product without selecting quantity */}
            <span
              className="errorMessage "
              style={{
                position: "absolute",
                right: "-4px",
                top: "-20px",
                width: "max-content",
              }}
            >
              {error}
            </span>
            {productQuantity > 0 && (
              <div className="quantity">
                <button
                  className="decre"
                  disabled={productQuantity === 1}
                  style={{
                    cursor: productQuantity <= 0 ? "no-drop" : "pointer",
                  }}
                  onClick={() => {
                    setProductQuantity(productQuantity - 1);
                    if (productQuantity === 0) {
                      dispatch(AddToCart(props.id, getUser()));
                      dispatch(GetCartlist());
                    } else {
                      dispatch(AddToCart(props.id, -1));
                      dispatch(GetCartlist());
                    }
                  }}
                  // style={{borderRadius: "100%"}}
                >
                  <AiOutlineMinusCircle />
                </button>
                <span>{productQuantity}</span>
                <button
                  className="incre"
                  style={{
                    cursor: productQuantity >= 50 ? "no-drop" : "pointer",
                  }}
                  disabled={productQuantity >= 50}
                  onClick={() => {
                    dispatch(AddToCart(props.id, 1));
                    dispatch(GetCartlist());
                    setProductQuantity(productQuantity + 1);
                  }}
                >
                  <BsPlusCircle />
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className="productCart__details__addToCart "
          style={{ cursor: props.inStock === 0 ? "not-allowed" : "pointer" }}
          onClick={
            props.inStock !== 0
              ? handelAddToCart
              : console.log("product is out of stock")
          }
        >
          {/* {!isAdded ? "Add to cart" : "Added to Cart"}/ */}

          {loading ? (
            <div
              style={{
                borderRadius: "50%",
                height: "2.5rem",
                width: "2.5rem",
                background: "red",
                overflow: "hidden",
              }}
            >
              <img
                src={checkmark}
                className="bg-success"
                alt=""
                style={{
                  color: "red",
                  background: "white",
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              />
            </div>
          ) : (
            // "Add to cart"
            <>{!productQuantity ? "Add to cart" : "Remove from cart"}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewProductCard;
